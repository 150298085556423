import vi from './app/vi.json';
export default {
  ...vi,
  'header.homepage': 'Trang chủ',
  'header.solution': 'Giải pháp',
  'header.product': 'Sản phẩm',
  'header.customer': 'Khách hàng',
  'header.help': 'Hướng dẫn sử dụng',
  'header.pricing': 'Giá',
  'header.blog': 'Blog',
  'header.start_button': 'Dùng thử',
  'header.login': 'Đăng nhập',
  'header.contact': 'Liên hệ',
  'header.use_cases': 'Use Cases',
  'footer.contact': 'Thông tin liên hệ',
  'footer.contact_number': 'Phòng Kinh Doanh: 0249 9999 468',
  'footer.company_name': 'CÔNG TY TNHH GIẢI PHÁP ETRON',
  'footer.address': 'Địa chỉ',
  'footer.company_address':
    'Trụ sở: Số nhà 66 Hẻm 89/36/2 phố Phan Kế Bính, Phường Cống Vị, Quận Ba Đình, Thành phố Hà Nội.',
    'footer.company_office':
    'Văn phòng: Số 52 Phố Chùa hà, Phường Quan Hoa, Quận Cầu Giấy, Thành phố Hà Nội.',
  'footer.company_license': '',
    // 'Giấy chứng nhận đăng ký doanh nghiệp số 0109690915 Do Phòng ĐKKD - Sở KHĐT thành phố Hà Nội',
  'footer.terms_and_conditions': 'Chính sách & điều khoản',
  'footer.terms_using': 'Điều khoản sử dụng',
  'footer.terms_privacy': 'Chính sách bảo mật',
  'footer.terms_delivery': 'Chính sách giao vận',
  'footer.terms_refund': 'Chính sách hoàn trả',
  'footer.terms_payment': 'Chính sách thanh toán',
  'banner1.title': 'Nền tảng Callbot gọi tự động',
  'banner1.subtitle':
    'Giải pháp Auto call Telesales, Telemarketing & Chăm sóc khách hàng\ngiúp doanh nghiệp tối ưu chi phí, thời gian và gia tăng hiệu quả bán hàng.',
  'banner1.button1': 'Liên hệ tư vấn',
  'banner1.button2': 'Trải nghiệm miễn phí',
  'banner1.button2b': 'Trải nghiệm ngay',
  'use_case.title': 'Ứng dụng giải pháp',
  'use_case.subtitle': 'Giải pháp Telesales, Telemarketing & Chăm sóc khách hàng tự động',
  'demo.title': 'Trải nghiệm Callbot',
  'demo.select_script': 'Lựa chọn kịch bản',
  'demo.real_estate': 'Bất động sản (ghi âm)',
  'demo.finance': 'Tài chính (ghi âm)',
  'demo.education': 'Giáo dục (cá nhân hoá)',
  'demo.beauty': 'Thẩm mỹ viện (cá nhân hoá)',
  'demo.form.name.required_message': 'Vui lòng nhập tên Anh/Chị',
  'demo.form.name.placeholder': 'Họ tên Anh/Chị',
  'demo.form.phone_number.required_message': 'Vui lòng nhập số điện thoại',
  'demo.form.phone_number.placeholder': 'Số điện thoại',
  'demo.form.gender.placeholder': 'Vui lòng chọn xưng danh',
  'demo.form.gender.male': 'Anh',
  'demo.form.gender.female': 'Chị',
  'demo.form.call': 'Gọi thử',
  'products.CALLBOT_AI_AUTO_CALL': 'Callbot AI gọi tự động',
  'products.CALL_CENTER': 'Call Center',
  'products.CUSTOMERS_DATA': 'Tìm kiếm khách hàng',
  'solution.title': 'Giải pháp của UCALL',
  'solution.subtitle': 'Telesales, Telemarketing & Chăm sóc khách hàng tự động',
  'solution.data.title': 'Giải pháp tổng đài ảo thông minh',
  'solution.data.description1':
    '• Tổng đài ảo giúp doanh nghiệp vận hành hiệu quả và tối ưu chi phí',
  'solution.data.description2':
    '• Quay số telelsale tự động giúp gia tăng x2 hiệu quả gọi điện của nhân viên',
  'solution.call.title': 'Callbot gọi tự động',
  'solution.call.description1': '• Thực hiện hàng ngàn cuộc gọi đồng thời tới khách hàng',
  'solution.call.description2': '• Thiết kế kịch bản thông minh, tương tác tự nhiên',
  'solution.call.description3': '• Báo cáo kết quả chiến dịch trực quan, dễ hiểu',
  'solution.cta': 'Tìm Hiểu Thêm',

  'callbot.solution1.title': 'Giải pháp telesales',
  'callbot.solution1.subtitle': 'Giải pháp Callbot AI Auto Call gia tăng hiệu quả kinh doanh',
  'callbot.solution1.part_1.text':
    'Phần mềm Auto Call giúp doanh nghiệp gọi hàng ngàn cuộc gọi tự động và thông minh chỉ với 1 click chuột',
  'callbot.solution1.part_2.text':
    'Kịch bản callbot AI linh hoạt với giọng nói tự nhiên tạo nghe và phản hồi khách hàng thông minh giống 99% người gọi',

  'call_center.solution1.title': 'Giải pháp tổng đài',
  'call_center.solution1.subtitle': 'Giải pháp tổng đài ảo thông minh cho doanh nghiệp',
  'call_center.solution1.part_1.text':
    'Dịch vụ tổng đài ảo thông minh giúp doanh nghiệp quản lý, vận hành đội ngũ Telesales và chăm sóc khách hàng hiệu quả',
  'call_center.solution1.part_2.text':
    'Phần mềm Telesales giúp tăng 200% năng xuất lao động của nhân viên Telesales, Telemarketing và chăm sóc khách hàng',

  'uleads.solution1.title': 'Giải pháp data khách hàng',
  'uleads.solution1.subtitle': 'Giải pháp tìm kiếm data khách hàng thông minh cho doanh nghiệp',
  'uleads.solution1.part_1.text':
    'Giải pháp tìm kiếm data khách hàng doanh nghiệp B2B và data khách hàng quét số điện thoại từ Facebook cho Telesales',
  'uleads.solution1.part_2.text':
    'Dữ liệu lớn làm giàu data khách hàng giúp doanh nghiệp thấu hiểu insight khách hàng',

  'callbot.problems.title': 'Vấn đề telesales của doanh nghiệp',
  'callbot.problems.problem1.title': 'Mất nhiều thời gian Telesales mà không hiệu quả',
  'callbot.problems.problem1.description':
    'Khả năng xử lý cuộc gọi của nhân viên thấp và tốn thời gian quản lý chất lượng cuộc gọi của nhân viên dẫn đến việc mất khách hàng vào tay đối thủ.',
  'callbot.problems.problem2.title': 'Không tuyển được Telesales cho dự án lớn',
  'callbot.problems.problem2.description':
    'Với các dự án lớn, doanh nghiệp cần tuyển gấp một số lượng người làm telesales. Tuy nhiên quá trình tuyển dụng và đào tạo tốn nhiều thời gian, các dịch vụ telesales thì tốn kém và khó quản lý chất lượng cuộc gọi.',
  'callbot.problems.problem3.title': 'Tốn nguồn lực vận hành tổng đài',
  'callbot.problems.problem3.description':
    'Doanh nghiệp tốn nhiều chi phí để chi trả cho cước viễn thông, nhân viên, văn phòng, quản lý và mua thiết bị tổng đài đắt tiền nhưng hoạt động chưa hiệu quả.',

  'call_center.problems.title': 'Vấn đề tổng đài của doanh nghiệp',
  'call_center.problems.problem1.title': 'Vận hành hệ thống tổng đài kém hiệu quả',
  'call_center.problems.problem1.description':
    'Doanh nghiệp tốn nhiều chi phí cho cước viễn thông, nhân sự, quản lý và thiết bị tổng đài đắt tiền nhưng chưa tối ưu được hiệu quả của nhân viên tổng đài.',
  'call_center.problems.problem2.title': 'Khó khăn trong việc quản lý chất lượng cuộc gọi',
  'call_center.problems.problem2.description':
    'Doanh nghiệp khó khăn trong việc đánh giá chất lượng cuộc gọi của nhân viên. Từ đó, doanh nghiệp chưa tối ưu được hiệu quả tổng đài Telesales, telemarketing và chăm sóc khách hàng.',
  'call_center.problems.problem3.title': 'Trải nhiệm khách hàng chưa được tối ưu',
  'call_center.problems.problem3.description':
    'Doanh nghiệp chưa tối ưu được hiệu quả tổng đài, chưa đồng bộ dữ liệu khách hàng và quy trình bán hàng khiến khách hàng không được chăm sóc kịp thời và phù hợp.',

  'uleads.problems.title': 'Vấn đề data khách hàng của doanh nghiệp',
  'uleads.problems.problem1.title': 'Thiếu nguồn khách hàng tiềm năng',
  'uleads.problems.problem1.description':
    'Doanh nghiệp gặp khó khăn trong việc tạo ra tập khách hàng tiềm năng chất lượng vì thiếu công cụ cụ tìm kiếm và phân lọc khách hàng tiềm năng mới.',
  'uleads.problems.problem2.title': 'Không thấu hiểu insight khách hàng',
  'uleads.problems.problem2.description':
    'Data khách hàng của doanh nghiệp không được làm giàu và khai thác một cách hiệu quả. Từ đó, chưa tận dụng được data khách hàng để tối ưu hiệu quả kinh doanh.',
  'uleads.problems.problem3.title': 'Chi phí tìm kiếm khách hàng tăng cao',
  'uleads.problems.problem3.description':
    'Chi phí chạy quảng cáo tìm ra khách hàng tiềm năng ngày càng cao. Doanh nghiệp chưa tiếp cận được các giải pháp tìm kiếm khách hàng thông minh và tiết kiệm.',

  'callbot.features.title': 'Tính năng Callbot AI',
  'callbot.features.feature1.title': '1. Xây dựng kịch bản',
  'callbot.features.feature1.0.title':
    '1. Xây dựng kịch bản telesale thực chiến thiết kế kịch bản telesale ',
  'callbot.features.feature1.0.description':
    'Phần mềm gọi điện thoại tự động auto call có giao diện thiết kế kịch bản telesale bán hàng, chăm sóc khách hàng kéo thả thông minh với các block tính năng cho phép người dùng cá nhân hóa xây dựng kịch bản telesale cho chiến dịch bán hàng.',
  'callbot.features.feature1.1.title': 'Kịch bản mẫu',
  'callbot.features.feature1.1.description':
    '200+ kịch bản AI telesales mẫu thực chiến được nghiên cứu và thiết kế tối ưu cho các ngành nghề như: Bất động sản, tài chính, bảo hiểm, giáo dục, spa, thời trang, bán lẻ, vv.',
  'callbot.features.feature1.2.title': 'Chuyển máy tới nhân viên',
  'callbot.features.feature1.2.description':
    'Dịch vụ auto call kết hợp linh hoạt giữa cuộc gọi tự động AI Call và chuyển máy tới nhân viên telesale giúp gia tăng trải nghiệm khách hàng, tăng gấp 2 lần cơ hội chốt sale.',
  'callbot.features.feature2.title': '2. Cài đặt chiến dịch thông minh',
  'callbot.features.feature2.0.title': 'Giọng nói',
  'callbot.features.feature2.0.description':
    'Lựa chọn giọng nói cho trợ lý ảo giống 99% người thật với đẩy đủ giọng nam nữ 3 vùng miền. Ngoài ra, hỗ trợ ghi âm giọng nói chuyên nghiệp giúp khách hàng trải nghiệm cuộc gọi thực tế nhất.',
  'callbot.features.feature2.1.title': 'Khung thời gian gọi',
  'callbot.features.feature2.1.description':
    'Trợ lý ảo auto call gọi vào những khung giờ vàng đã được cài đặt giúp bạn yên tâm chạy chiến dịch đúng thời điểm mà không cần huy động nguồn lực.',
  'callbot.features.feature2.2.title': 'Cài đặt gọi lại',
  'callbot.features.feature2.2.description':
    'Tool gọi điện tự động gọi lại cho khách hàng máy bận, không nghe máy theo số lần được cài đặt, giúp tiết kiệm thời gian quay số gọi lại.',
  'callbot.features.feature3.title': '3. Báo cáo và đánh giá chi tiết',
  'callbot.features.feature3.0.title': 'Báo cáo tổng quan',
  'callbot.features.feature3.0.description':
    'Báo cáo tổng quan của dịch vụ auto call AI Callbot giúp đánh giá tiến độ chiến dịch gọi tự động, chi phí cuộc gọi, trạng thái cuộc gọi trong từng khung giờ, thời lượng cuộc gọi. Từ đó giúp bạn đánh giá tổng quan hiệu quả chiến dịch AI telesales, để chỉnh sửa kịch bản telesale, và quản lý telesale hiệu quả.',
  'callbot.features.feature3.1.title': 'Báo cáo chi tiết',
  'callbot.features.feature3.1.description':
    'Báo cáo chi tiết của phần mềm auto call AI Callbot cho phép xuất báo cáo Excel, nghe lại file ghi âm, chuyển cuộc gọi sang dạng văn bản (speech to text) và phân loại khách hàng tiềm năng theo thông tin cuộc gọi dễ dàng.',
  'callbot.features.feature3.2.title': 'Đánh giá chất lượng cuộc gọi',
  'callbot.features.feature3.2.description':
    'Giải pháp auto call AI Callbot sẽ tự động đánh giá chất lượng cuộc gọi bằng AI, giúp quản lý telesale hiệu quả và nội dung cuộc gọi AI Call nhanh chóng.',
  'callbot.features.feature4.title': ' 4. Tích hợp dễ dàng, đa kênh',
  'callbot.features.feature4.0.title': 'Tích hợp tìm kiếm data khách hàng tiềm năng ULEAD',
  'callbot.features.feature4.0.description':
    'Công cụ tìm kiếm data khách hàng ULEAD giúp doanh nghiệp lấy số điện thoại từ uid facebook, tìm ra nguồn khách hàng tiềm năng B2B, B2C từ dữ liệu lớn nhanh chóng và dễ dàng. ',
  'callbot.features.feature4.1.title': 'Tích hợp tổng đài ảo telesale Call Center',
  'callbot.features.feature4.1.description':
    'Data khách hàng được tích hợp vào Tổng đài ảo Call Center Quay số tự động, giúp quản lý thông tin khách hàng dễ dàng hơn, nhân viên telesale tập trung bán hàng.',
  'callbot.features.feature4.2.title': 'Tích hợp SMS, brandname, CRM, API',
  'callbot.features.feature4.2.description':
    'Data khách hàng được tích hợp vào SMS tin nhắn, SMS brandname, hệ thống CRM và API giúp tăng 30% tỷ lệ chuyển đổi bán hàng.',

  'call_center.features.title': 'Tính năng Dịch Vụ Tổng Đài Ảo Call Center',
  'call_center.features.feature1.title': '1. Quay số nhanh',
  'call_center.features.feature1.0.title': 'Quay số tự động',
  'call_center.features.feature1.0.description':
    'Dịch vụ call center cho phép quay số nhanh tự động và phân công cuộc gọi cho nhân viên giúp tăng hiệu suất làm việc gấp 3 lần so với gọi bán hàng qua điện thoại thông thường.',
  'call_center.features.feature1.1.title': 'Ghi chú thông tin nhanh',
  'call_center.features.feature1.1.description':
    'Dịch vụ tổng đài ảo cho phép nhân viên ghi chú tức thời nội dung trao đổi với khách hàng ngay trong cuộc gọi, dễ dàng tìm kiếm lại thông tin khách hàng.',
  'call_center.features.feature1.2.title': '',
  'call_center.features.feature1.2.description': '',
  'call_center.features.feature2.title': '2. Xây dựng chiến dịch gọi điện',
  'call_center.features.feature2.0.title': 'Kịch bản telesale tư vấn bán hàng',
  'call_center.features.feature2.0.description':
    'Tổng đài ảo cho phép quản lý mô tả chiến dịch và xây dựng kịch bản telesale bán hàng, giúp giảm thời gian phổ biến và đào tạo nhân viên.',
  'call_center.features.feature2.1.title': 'Tự động lựa chọn đầu số tối ưu chi phí',
  'call_center.features.feature2.1.description':
    'Tổng đài Call Center cho phép người dùng lựa chọn đầu số gọi nhằm tối ưu chi phí cước viễn thông và tránh bị chặn số, giúp telesale làm việc hiệu quả.',
  'call_center.features.feature2.2.title': 'Cài đặt gọi lại',
  'call_center.features.feature2.2.description':
    'Phần mềm Call center tự động phân phối cuộc gọi cần gọi lại cho nhân viên telesales theo khung thời gian và số lần gọi lại được cài đặt bởi quản lý. ',
  'call_center.features.feature3.title': '3. Làm giàu thông tin khách hàng',
  'call_center.features.feature3.0.title': 'Báo cáo tổng quan',
  'call_center.features.feature3.0.description':
    'Báo cáo tổng quan của tổng đài ảo Call Center giúp đánh giá chất lượng chiến dịch, quản lý telesale hiệu quả.',
  'call_center.features.feature3.1.title': 'Báo cáo chi tiết',
  'call_center.features.feature3.1.description':
    'Báo cáo chi tiết của Tổng đài ảo cho phép xuất báo cáo Excel, nghe lại file ghi âm, nhãn dán, chuyển cuộc gọi dưới dạng văn bản (speech to text) và phân loại khách hàng tiềm năng theo thông tin cuộc gọi dễ dàng.',
  'call_center.features.feature3.2.title': 'Đánh giá chất lượng cuộc gọi',
  'call_center.features.feature3.2.description':
    'Hệ thống Call Center tự động đánh giá chất lượng cuộc gọi bằng AI giúp quản lý telesale hiệu quả và nội dung cuộc gọi nhanh chóng.',
  'call_center.features.feature4.title': '4. Tích hợp dễ dàng, đa kênh',
  'call_center.features.feature4.0.title': 'Tích hợp tìm kiếm data khách hàng ULEAD',
  'call_center.features.feature4.0.description':
    'Công cụ tìm kiếm data khách hàng ULEAD giúp doanh nghiệp lấy số điện thoại từ uid facebook, tìm ra nguồn khách hàng tiềm năng B2B, B2C từ dữ liệu lớn nhanh chóng và dễ dàng. ',
  'call_center.features.feature4.1.title': 'Tích hợp dịch vụ auto call AI Callbot',
  'call_center.features.feature4.1.description':
    'Data khách hàng tích hợp vào giải pháp auto call AI Callbot để tiết kiệm 80% thời gian tiếp cận hàng nghìn khách hàng, phân loại khách hàng tiềm năng nhanh chóng.',
  'call_center.features.feature4.2.title': 'Tích hợp SMS, brandname, CRM, API',
  'call_center.features.feature4.2.description':
    'Data khách hàng được tích hợp vào SMS tin nhắn, SMS brandname, hệ thống CRM và API giúp tăng 30% tỷ lệ chuyển đổi bán hàng.',

  'uleads.features.title': 'Tính năng ULEADS',
  'uleads.features.feature1.title': 'Tìm kiếm data',
  'uleads.features.feature1.0.title': 'Quét số điện thoại từ Facebook',
  'uleads.features.feature1.0.description':
    'Quét data số điện thoại thành viên nhóm Facebook để mở rộng tập khách hàng tiềm năng.',
  'uleads.features.feature1.1.title': 'Tìm kiếm data khách hàng doanh nghiệp',
  'uleads.features.feature1.1.description':
    'Lọc data doanh nghiệp đăng ký kinh doanh theo ngành nghề, khu vực, loại hình, thời gian thành lập doanh nghiệp.',
  'uleads.features.feature1.2.title': '',
  'uleads.features.feature1.2.description': '',
  'uleads.features.feature2.title': 'Callbot gọi lọc data',
  'uleads.features.feature2.0.title': 'Callbot AI Auto call',
  'uleads.features.feature2.0.description':
    'Giải pháp gọi tự động hàng ngành khách hàng bằng callbot AI chỉ với 1 click chuột giúp lọc nhanh khách hàng với chi phí tiết kiệm.',
  'uleads.features.feature2.1.title': 'Báo cáo phân loại khách hàng',
  'uleads.features.feature2.1.description':
    'Báo cáo phân loại khách hàng theo nhãn dán giúp nhanh chóng lọc khách hàng quan tâm tới sản phẩm, dịch vụ của doanh nghiệp.',
  'uleads.features.feature2.2.title': '',
  'uleads.features.feature2.2.description': '',
  'uleads.features.feature3.title': 'Làm giàu thông tin khách hàng',
  'uleads.features.feature3.0.title': 'Tìm kiếm Facebook khách hàng',
  'uleads.features.feature3.0.description':
    'Tự động tìm kiếm Facebook cho các số điện thoại của khách hàng giúp doanh nghiệp thấu hiểu khách hàng và gia tăng hiệu quả tư vấn và bán hàng.',
  'uleads.features.feature3.1.title': 'Tìm kiếm số điện thoại khách hàng',
  'uleads.features.feature3.1.description':
    'Báo cáo chi tiết cho phép nghe lại audio ghi âm cuộc gọi, chuyển cuộc gọi dưới dạng văn bản (speech to text) và lọc data khách hàng theo thông tin cuộc gọi dễ dàng.',
  'uleads.features.feature3.2.title': '',
  'uleads.features.feature3.2.description': '',
  'uleads.features.feature4.title': 'Tích hợp dễ dàng',
  'uleads.features.feature4.0.title': 'Tích hợp chiến dịch gọi tự động',
  'uleads.features.feature4.0.description':
    'Chiến dịch gọi điện tự động bằng cộng nghệ trí tuệ nhân tạo giúp tiết kiệm 80% thời gian và chi phí tiếp cận và tìm kiếm khách hàng tiềm năng.',
  'uleads.features.feature4.1.title': 'Tích hợp tổng đài',
  'uleads.features.feature4.1.description':
    'Tích hợp giải pháp tổng đài ảo giúp kết hợp chiến dịch gọi tự động và gọi thủ công trên cùng một nền tảng, giúp dễ dàng chăm sóc và quản lý khách hàng hiệu quả tiết kiệm thời gian của nhân viên và chi phí tổng đài.',
  'uleads.features.feature4.2.title': 'Tích hợp nhắn tin',
  'uleads.features.feature4.2.description':
    'Tin nhắn SMS marketing, SMS brandname giúp marketing và chăm sóc khách hàng qua tin nhắn tích hợp với từng cuộc gọi tương tác với khách hàng tức thời và đa kênh giúp tăng 30% tỉ lệ chuyển đổi.',

  'bds.features.title': 'Tính năng Callbot Ngành Bất Động Sản',
  'bds.features.feature1.title': '1. Xây dựng kịch bản telesale thực chiến ',
  'bds.features.feature1.0.title': 'Thiết kế kịch bản telesale bất động sản',
  'bds.features.feature1.0.description':
    'Phần mềm auto call AI Callbot hỗ trợ xây kịch bản telesale bđs kéo thả thông minh với các block tính năng cho công ty bất động sản cá nhân hóa xây dựng kịch bản telesale bđs đầu tư đất nền, mua chung cư, căn hộ, vv.',
  'bds.features.feature1.1.title': 'Kịch bản mẫu',
  'bds.features.feature1.1.description':
    '200+ kịch bản telesale bđs dự án thực chiến được nghiên cứu và thiết kế tối ưu cho các ngành nghề khác như: Bất động sản, tài chính, bảo hiểm, giáo dục, spa, thời trang, bán lẻ, vv.',
  'bds.features.feature1.2.title': 'Chuyển máy tới nhân viên',
  'bds.features.feature1.2.description':
    'Dịch vụ auto call AI Callbot kết hợp linh hoạt giữa cuộc gọi tự động và chuyển máy tới nhân viên telesale bất động sản giúp gia tăng trải nghiệm khách hàng, tăng gấp 2 lần cơ hội chốt sale bất động sản. ',
  'bds.features.feature2.title': '2. Cài đặt chiến dịch thông minh',
  'bds.features.feature2.0.title': 'Giọng nói',
  'bds.features.feature2.0.description':
    'Lựa chọn giọng nói cho trợ lý ảo giống 99% người thật với đẩy đủ giọng nam nữ 3 vùng miền. Ngoài ra, hỗ trợ ghi âm giọng nói chuyên nghiệp giúp khách hàng trải nghiệm cuộc gọi thực tế nhất.',
  'bds.features.feature2.1.title': 'Khung thời gian gọi',
  'bds.features.feature2.1.description':
    'Trợ lý ảo auto call gọi vào những khung giờ vàng đã được cài đặt giúp bạn yên tâm chạy chiến dịch telesale bất động sản đúng thời điểm mà không cần huy động nguồn lực.',
  'bds.features.feature2.2.title': 'Cài đặt gọi lại ',
  'bds.features.feature2.2.description':
    'Hệ thống tự động gọi lại cho khách hàng máy bận, không nghe máy theo số lần được cài đặt, giúp tiết kiệm thời gian quay số gọi lại. ',
  'bds.features.feature3.title': '3. Báo cáo và đánh giá chi tiết',
  'bds.features.feature3.0.title': 'Báo cáo tổng quan',
  'bds.features.feature3.0.description':
    'Báo cáo tổng quan của dịch vụ auto call AI Callbot giúp đánh giá tiến độ chiến dịch telesale bất động sản, chi phí, thời lượng, trạng thái cuộc gọi trong từng khung giờ. Từ đó giúp đánh giá tổng quan hiệu quả chiến dịch, để chỉnh sửa kịch bản telesale bđs, và quản lý telesale bất động sản hiệu quả.',
  'bds.features.feature3.1.title': 'Báo cáo chi tiết',
  'bds.features.feature3.1.description':
    'Báo cáo chi tiết của phần mềm auto call AI Callbot cho phép xuất báo cáo Excel, nghe lại file ghi âm, chuyển cuộc gọi sang dạng văn bản (speech to text) và phân loại data khách hàng bds theo thông tin cuộc gọi dễ dàng.',
  'bds.features.feature3.2.title': 'Đánh giá chất lượng cuộc gọi',
  'bds.features.feature3.2.description':
    'Giải pháp auto call AI Callbot sẽ tự động đánh giá chất lượng cuộc gọi bằng AI, giúp quản lý telesale bất động sản hiệu quả và nội dung cuộc gọi nhanh chóng. ',
  'bds.features.feature4.title': ' 4. Tích hợp dễ dàng, đa kênh',
  'bds.features.feature4.0.title': 'Tích hợp tìm kiếm data khách hàng tiềm năng ULEAD',
  'bds.features.feature4.0.description':
    'Công cụ tìm kiếm data khách hàng ULEAD giúp công ty bất động sản lấy số điện thoại từ uid facebook, tìm kiếm data khách hàng bds tiềm năng B2B, B2C từ big data nhanh chóng và dễ dàng.',
  'bds.features.feature4.1.title': 'Tích hợp tổng đài ảo telesale Call Center',
  'bds.features.feature4.1.description':
    'Data khách hàng bds được tích hợp vào Tổng đài ảo Call Center Quay số nhan tự động, giúp quản lý thông tin khách hàng bất động sản dễ dàng hơn, nhân viên telesale tập trung bán hàng.',
  'bds.features.feature4.2.title': 'Tích hợp SMS, brandname, CRM, API',
  'bds.features.feature4.2.description':
    'Data khách hàng đầu tư dự án bất động sản được tích hợp vào SMS tin nhắn, SMS brandname, hệ thống CRM và API giúp tăng 30% tỷ lệ chuyển đổi bán hàng.',

  'taichinh.features.title': 'Tính năng Callbot Ngành Tài Chính',
  'taichinh.features.feature1.title': '1. Xây dựng kịch bản telesale thực chiến ',
  'taichinh.features.feature1.0.title': 'Thiết kế kịch bản telesale tài chính',
  'taichinh.features.feature1.0.description':
    'Phần mềm auto call AI Callbot hỗ trợ xây kịch bản telesale tài chính với các block tính năng cho phép công ty tài chính cá nhân hóa xây dựng kịch bản telesale vay vốn tiêu dùng, thông báo nhắc nợ khách hàng, mua vay tài chính, vay sinh viên, vv.',
  'taichinh.features.feature1.1.title': 'Kịch bản mẫu',
  'taichinh.features.feature1.1.description':
    '200+ kịch bản telesale tài chính thực chiến được nghiên cứu và thiết kế cho các ngành nghề khác như: Bất động sản, bảo hiểm, giáo dục, spa, thời trang, bán lẻ, vv.',
  'taichinh.features.feature1.2.title': 'Chuyển máy tới nhân viên',
  'taichinh.features.feature1.2.description':
    'Dịch vụ auto call AI Callbot kết hợp linh hoạt cuộc gọi tự động và chuyển máy tới nhân viên telesale tài chính giúp gia tăng trải nghiệm khách hàng, tăng gấp 2 lần cơ hội chốt sale.  ',
  'taichinh.features.feature2.title': '2. Cài đặt chiến dịch thông minh',
  'taichinh.features.feature2.0.title': 'Giọng nói',
  'taichinh.features.feature2.0.description':
    'Lựa chọn giọng nói cho trợ lý ảo giống 99% người thật với đẩy đủ giọng nam nữ 3 vùng miền. Ngoài ra, hỗ trợ ghi âm giọng nói chuyên nghiệp giúp khách hàng trải nghiệm cuộc gọi thực tế nhất.',
  'taichinh.features.feature2.1.title': 'Khung thời gian gọi',
  'taichinh.features.feature2.1.description':
    'Phần mềm auto call gọi vào những khung giờ vàng đã được cài đặt giúp bạn yên tâm chạy chiến dịch telesale tài chính vay vốn đúng thời điểm mà không cần huy động nguồn lực.',
  'taichinh.features.feature2.2.title': 'Cài đặt gọi lại ',
  'taichinh.features.feature2.2.description':
    'Hệ thống tự động gọi lại cho khách hàng vay vốn máy bận, không nghe máy theo số lần được cài đặt, giúp tiết kiệm thời gian quay số gọi lại.  ',
  'taichinh.features.feature3.title': '3. Báo cáo và đánh giá chi tiết',
  'taichinh.features.feature3.0.title': 'Báo cáo tổng quan',
  'taichinh.features.feature3.0.description':
    'Báo cáo tổng quan của dịch vụ auto call AI Callbot giúp đánh giá tiến độ chiến dịch telesale tài chính, chi phí, thời lượng, trạng thái cuộc gọi trong từng khung giờ. Từ đó giúp đánh giá tổng quan hiệu quả chiến dịch, để chỉnh sửa kịch bản telesale tài chính, và quản lý telesale tài chính hiệu quả.',
  'taichinh.features.feature3.1.title': 'Báo cáo chi tiết',
  'taichinh.features.feature3.1.description':
    'Báo cáo chi tiết của phần mềm auto call AI Callbot cho phép xuất báo cáo Excel, nghe lại file ghi âm, chuyển cuộc gọi sang dạng văn bản (speech to text) và phân loại data vay vốn theo thông tin cuộc gọi dễ dàng.',
  'taichinh.features.feature3.2.title': 'Đánh giá chất lượng cuộc gọi',
  'taichinh.features.feature3.2.description':
    'Giải pháp auto call AI Callbot sẽ tự động đánh giá chất lượng cuộc gọi bằng AI, giúp quản lý telesale tài chính hiệu quả và nội dung cuộc gọi nhanh chóng.  ',
  'taichinh.features.feature4.title': ' 4. Tích hợp dễ dàng, đa kênh',
  'taichinh.features.feature4.0.title': 'Tích hợp tìm kiếm data khách hàng tiềm năng ULEAD',
  'taichinh.features.feature4.0.description':
    'Công cụ tìm kiếm data khách hàng ULEAD giúp công ty tài chính lấy số điện thoại từ uid facebook, tìm kiếm khách hàng có nhu cầu vay vốn B2B, B2C từ big data nhanh chóng.',
  'taichinh.features.feature4.1.title': 'Tích hợp tổng đài ảo telesale Call Center',
  'taichinh.features.feature4.1.description':
    'Data khách hàng vay vốn được tích hợp vào Tổng đài ảo Call Center Quay số nhanh, giúp quản lý data khách hàng tài chính dễ dàng hơn, nhân viên telesale tài chính tập trung bán hàng.',
  'taichinh.features.feature4.2.title': 'Tích hợp SMS, brandname, CRM, API',
  'taichinh.features.feature4.2.description':
    'Data khách hàng vay vốn được tích hợp vào SMS tin nhắn, SMS brandname, hệ thống CRM và API giúp tăng 30% tỷ lệ chuyển đổi bán hàng.',

  'giaoduc.features.title': 'Tính năng Callbot Ngành Giáo dục',
  'giaoduc.features.feature1.title': '1. Xây dựng kịch bản telesale thực chiến ',
  'giaoduc.features.feature1.0.title': 'Thiết kế kịch bản telesale giáo dục',
  'giaoduc.features.feature1.0.description':
    'Phần mềm auto call AI Callbot hỗ trợ xây kịch bản telesale giáo dục với các block tính năng cho phép nhân viên cá nhân hóa xây dựng kịch bản telesale giáo dục gọi giới thiệu khóa học, thông báo học phí, thông báo lịch học, báo cáo tình hình học tập, vv.',
  'giaoduc.features.feature1.1.title': 'Kịch bản mẫu',
  'giaoduc.features.feature1.1.description':
    '200+ kịch bản telesale giáo dục thực chiến được nghiên cứu và thiết kế cho các ngành nghề khác như: Bất động sản, bảo hiểm, spa, thời trang, bán lẻ, vv.',
  'giaoduc.features.feature1.2.title': 'Chuyển máy tới nhân viên',
  'giaoduc.features.feature1.2.description':
    'Dịch vụ auto call AI Callbot kết hợp linh hoạt cuộc gọi tự động và chuyển máy tới nhân viên telesale giáo dục giúp gia tăng trải nghiệm khách hàng, tăng gấp 2 lần cơ hội chốt sales bán khóa học.  ',
  'giaoduc.features.feature2.title': '2. Cài đặt chiến dịch thông minh',
  'giaoduc.features.feature2.0.title': 'Giọng nói',
  'giaoduc.features.feature2.0.description':
    'Lựa chọn giọng nói cho trợ lý ảo giống 99% người thật với đủ giọng nam nữ 3 vùng miền. Ngoài ra, hỗ trợ ghi âm giọng nói chuyên nghiệp giúp khách hàng trải nghiệm cuộc gọi thực tế nhất.',
  'giaoduc.features.feature2.1.title': 'Khung thời gian gọi',
  'giaoduc.features.feature2.1.description':
    'Phần mềm auto call gọi vào các khung giờ vàng đã được cài đặt giúp chạy chiến dịch telesale bán khóa học, chăm sóc học viên đúng thời điểm mà không cần nhiều nguồn lực.',
  'giaoduc.features.feature2.2.title': 'Cài đặt gọi lại ',
  'giaoduc.features.feature2.2.description':
    'Hệ thống tự động gọi lại cho khách hàng máy bận, không nghe máy theo số lần được cài đặt, giúp tiết kiệm thời gian quay số gọi lại.  ',
  'giaoduc.features.feature3.title': '3. Báo cáo và đánh giá chi tiết',
  'giaoduc.features.feature3.0.title': 'Báo cáo tổng quan',
  'giaoduc.features.feature3.0.description':
    'Báo cáo tổng quan của dịch vụ auto call AI Callbot giúp đánh giá tiến độ chiến dịch telesale tài chính, chi phí, thời lượng, trạng thái cuộc gọi trong từng khung giờ. Từ đó giúp đánh giá tổng quan hiệu quả chiến dịch, để chỉnh sửa kịch bản telesale tài chính, và quản lý telesale tài chính hiệu quả.',
  'giaoduc.features.feature3.1.title': 'Báo cáo chi tiết',
  'giaoduc.features.feature3.1.description':
    'Báo cáo chi tiết của phần mềm auto call AI Callbot cho phép xuất báo cáo Excel, nghe lại file ghi âm, chuyển cuộc gọi sang dạng văn bản (speech to text) và phân loại data sinh viên theo thông tin cuộc gọi dễ dàng.',
  'giaoduc.features.feature3.2.title': 'Đánh giá chất lượng cuộc gọi',
  'giaoduc.features.feature3.2.description':
    'Giải pháp auto call AI Callbot sẽ tự động đánh giá chất lượng cuộc gọi bằng AI, giúp quản lý telesale giáo dục hiệu quả và nội dung cuộc gọi nhanh chóng. ',
  'giaoduc.features.feature4.title': ' 4. Tích hợp dễ dàng, đa kênh',
  'giaoduc.features.feature4.0.title': 'Tích hợp tìm kiếm data khách hàng tiềm năng ULEAD',
  'giaoduc.features.feature4.0.description':
    'Công cụ tìm kiếm data khách hàng ULEAD giúp trung tâm giáo dục lấy số điện thoại từ uid facebook, tìm sinh viên muốn đăng ký khóa học từ cở sở dữ liệu giáo dục nhanh chóng.',
  'giaoduc.features.feature4.1.title': 'Tích hợp tổng đài ảo telesale Call Center',
  'giaoduc.features.feature4.1.description':
    'Data sinh viên được tích hợp vào Tổng đài ảo Call Center Quay số nhanh, giúp quản lý thông tin học sinh dễ dàng hơn, nhân viên telesale bán khóa học tập trung bán hàng.',
  'giaoduc.features.feature4.2.title': 'Tích hợp SMS, brandname, CRM, API',
  'giaoduc.features.feature4.2.description':
    'Data sinh viên được tích hợp vào SMS tin nhắn, SMS brandname, hệ thống CRM và API giúp tăng 30% tỷ lệ chuyển đổi bán hàng.',

  'spa.features.title': 'Tính năng Callbot Ngành Spa',
  'spa.features.feature1.title': '1. Xây dựng kịch bản telesale thực chiến ',
  'spa.features.feature1.0.title': 'Thiết kế kịch bản telesale thẩm mỹ viện và spa',
  'spa.features.feature1.0.description':
    'Phần mềm auto call AI Callbot hỗ trợ xây kịch bản telesale spa làm đẹp, thẩm mỹ viện với các block tính năng cho phép nhân viên cá nhân hóa xây dựng kịch bản telesale spa làm đẹp giúp chủ spa giới thiệu gói spa làm đẹp da, gói chăm sóc da mặt, spa dưỡng da, vv. ',
  'spa.features.feature1.1.title': 'Kịch bản mẫu',
  'spa.features.feature1.1.description':
    '200+ kịch bản telesale spa, thẩm mỹ viện thực chiến được nghiên cứu và thiết kế cho các ngành nghề khác như: Bất động sản, bảo hiểm, thời trang, bán lẻ, vv.',
  'spa.features.feature1.2.title': 'Chuyển máy tới nhân viên',
  'spa.features.feature1.2.description':
    'Dịch vụ auto call AI Callbot kết hợp linh hoạt cuộc gọi tự động và chuyển máy tới nhân viên telesale spa, thẩm mỹ viện giúp gia tăng trải nghiệm khách hàng, tăng gấp 2 lần cơ hội chốt sales dịch vụ spa làm đẹp. ',
  'spa.features.feature2.title': '2. Cài đặt chiến dịch thông minh',
  'spa.features.feature2.0.title': 'Giọng nói',
  'spa.features.feature2.0.description':
    'Lựa chọn giọng nói cho trợ lý ảo giống 99% người thật với đủ giọng nam nữ 3 vùng miền. Ngoài ra, hỗ trợ ghi âm giọng nói chuyên nghiệp giúp khách hàng trải nghiệm cuộc gọi thực tế nhất.',
  'spa.features.feature2.1.title': 'Khung thời gian gọi',
  'spa.features.feature2.1.description':
    'Phần mềm auto call gọi vào các khung giờ vàng đã được cài đặt giúp chạy chiến dịch telesale spa làm đẹp, chăm sóc khách hàng spa đúng thời điểm mà không cần nhiều nguồn lực.',
  'spa.features.feature2.2.title': 'Cài đặt gọi lại ',
  'spa.features.feature2.2.description':
    'Hệ thống tự động gọi lại cho khách hàng máy bận, không nghe máy theo số lần được cài đặt, giúp tiết kiệm thời gian quay số gọi lại.  ',
  'spa.features.feature3.title': '3. Báo cáo và đánh giá chi tiết',
  'spa.features.feature3.0.title': 'Báo cáo tổng quan',
  'spa.features.feature3.0.description':
    'Báo cáo tổng quan của dịch vụ auto call AI Callbot giúp đánh giá tiến độ chiến dịch telesale spa làm đẹp, chi phí, thời lượng, trạng thái cuộc gọi trong từng khung giờ. Từ đó giúp đánh giá tổng quan hiệu quả chiến dịch, để chỉnh sửa kịch bản telesale spa làm đẹp, và quản lý telesale hiệu quả.',
  'spa.features.feature3.1.title': 'Báo cáo chi tiết',
  'spa.features.feature3.1.description':
    'Báo cáo chi tiết của phần mềm auto call AI Callbot cho phép xuất báo cáo Excel, nghe lại file ghi âm, chuyển cuộc gọi sang dạng văn bản (speech to text) và phân loại data khách hàng có nhu cầu làm đẹp theo thông tin cuộc gọi dễ dàng.',
  'spa.features.feature3.2.title': 'Đánh giá chất lượng cuộc gọi',
  'spa.features.feature3.2.description':
    'Giải pháp auto call AI Callbot sẽ tự động đánh giá chất lượng cuộc gọi bằng AI, giúp quản lý telesale spa làm đẹp hiệu quả và nội dung cuộc gọi nhanh chóng.  ',
  'spa.features.feature4.title': ' 4. Tích hợp dễ dàng, đa kênh',
  'spa.features.feature4.0.title': 'Tích hợp tìm kiếm data khách hàng tiềm năng ULEAD',
  'spa.features.feature4.0.description':
    'Công cụ tìm kiếm data khách hàng ULEAD giúp chủ dịch vụ spa lấy số điện thoại từ uid facebook, tìm data khách hàng có nhu cầu đi spa làm đẹp từ dữ liệu lớn nhanh chóng',
  'spa.features.feature4.1.title': 'Tích hợp tổng đài ảo telesale Call Center',
  'spa.features.feature4.1.description':
    'Data khách hàng spa làm đẹp được tích hợp vào Tổng đài ảo Call Center Quay số nhanh, giúp quản lý thông tin khách hàng dễ dàng hơn, nhân viên telesale dịch vụ spa tập trung bán hàng.',
  'spa.features.feature4.2.title': 'Tích hợp SMS, brandname, CRM, API',
  'spa.features.feature4.2.description':
    'Data khách hàng spa làm đẹp được tích hợp vào SMS tin nhắn, SMS brandname, hệ thống CRM và API giúp tăng 30% tỷ lệ chuyển đổi bán hàng.',


    'baohiem.features.title': 'Tính năng Callbot Ngành Bảo Hiểm',
    'baohiem.features.feature1.title': '1. Xây dựng kịch bản telesale thực chiến ',
    'baohiem.features.feature1.0.title': 'Thiết kế kịch bản telesale bảo hiểm',
    'baohiem.features.feature1.0.description':
      'Phần mềm auto call AI Callbot hỗ trợ xây kịch bản telesale bảo hiểm với các block tính năng cho phép nhân viên cá nhân hóa xây dựng kịch bản telesale bảo hiểm giúp thay công ty tư vấn gói bảo hiểm như bảo hiểm sức khỏe, bảo hiểm nhân thọ, ..',
    'baohiem.features.feature1.1.title': 'Kịch bản mẫu',
    'baohiem.features.feature1.1.description':
      '200+ kịch bản thực chiến được nghiên cứu và thiết kế cho các ngành nghề khác như: Bất động sản, tài chính, giáo dục, spa, bán lẻ, vv.',
    'baohiem.features.feature1.2.title': 'Chuyển máy tới nhân viên',
    'baohiem.features.feature1.2.description':
      'Dịch vụ auto call AI Callbot kết hợp linh hoạt cuộc gọi tự động và chuyển máy tới nhân viên telesale bảo hiểm giúp gia tăng trải nghiệm khách hàng, tăng gấp 2 lần cơ hội bán bảo hiểm thành công.  ',
    'baohiem.features.feature2.title': '2. Cài đặt chiến dịch thông minh',
    'baohiem.features.feature2.0.title': 'Giọng nói',
    'baohiem.features.feature2.0.description':
      'Lựa chọn giọng nói cho trợ lý ảo giống 99% người thật với đủ giọng nam nữ 3 vùng miền. Ngoài ra, hỗ trợ ghi âm giọng nói chuyên nghiệp giúp khách hàng trải nghiệm cuộc gọi thực tế nhất.',
    'baohiem.features.feature2.1.title': 'Khung thời gian gọi',
    'baohiem.features.feature2.1.description':
      'Phần mềm auto call gọi vào các khung giờ vàng đã được cài đặt giúp chạy chiến dịch telesale bảo hiểm đúng thời điểm mà không cần nhiều nguồn lực.',
    'baohiem.features.feature2.2.title': 'Cài đặt gọi lại ',
    'baohiem.features.feature2.2.description':
      'Hệ thống tự động gọi lại cho data khách hàng bảo hiểm máy bận, không nghe máy theo số lần được cài đặt, giúp tiết kiệm thời gian quay số gọi lại.  ',
    'baohiem.features.feature3.title': '3. Báo cáo và đánh giá chi tiết',
    'baohiem.features.feature3.0.title': 'Báo cáo tổng quan',
    'baohiem.features.feature3.0.description':
      'Báo cáo tổng quan của dịch vụ auto call AI Callbot giúp đánh giá tiến độ chiến dịch telesale bảo hiểm, chi phí, thời lượng, trạng thái cuộc gọi trong từng khung giờ. Từ đó giúp đánh giá tổng quan hiệu quả chiến dịch, để chỉnh sửa kịch bản telesale bảo hiểm, và quản lý telesale hiệu quả.',
    'baohiem.features.feature3.1.title': 'Báo cáo chi tiết',
    'baohiem.features.feature3.1.description':
      'Báo cáo chi tiết của phần mềm auto call AI Callbot cho phép xuất báo cáo Excel, nghe lại file ghi âm, chuyển cuộc gọi sang dạng văn bản (speech to text) và phân loại data khách hàng có nhu cầu mua bảo hiểm theo thông tin cuộc gọi dễ dàng.',
    'baohiem.features.feature3.2.title': 'Đánh giá chất lượng cuộc gọi',
    'baohiem.features.feature3.2.description':
      'Giải pháp auto call AI Callbot sẽ tự động đánh giá chất lượng cuộc gọi bằng AI, giúp quản lý telesale bảo hiểm hiệu quả và nội dung cuộc gọi nhanh chóng. ',
    'baohiem.features.feature4.title': ' 4. Tích hợp dễ dàng, đa kênh',
    'baohiem.features.feature4.0.title': 'Tích hợp tìm kiếm data khách hàng tiềm năng ULEAD',
    'baohiem.features.feature4.0.description':
      'Công cụ tìm kiếm data khách hàng ULEAD giúp công ty bảo hiểm lấy số điện thoại từ uid facebook, tìm data khách hàng bảo hiểm có nhu cầu mua bảo hiểm từ dữ liệu lớn nhanh chóng.',
    'baohiem.features.feature4.1.title': 'Tích hợp tổng đài ảo telesale Call Center',
    'baohiem.features.feature4.1.description':
      'Data khách hàng bảo hiểm được tích hợp vào Tổng đài ảo Call Center Quay số nhanh, như một tổng đài bảo hiểm, giúp quản lý thông tin khách hàng dễ dàng hơn, nhân viên telesale bảo hiểm tập trung bán hàng.',
    'baohiem.features.feature4.2.title': 'Tích hợp SMS, brandname, CRM, API',
    'baohiem.features.feature4.2.description':
      'Data khách hàng bảo hiểm được tích hợp vào SMS tin nhắn, SMS brandname, hệ thống CRM và API giúp tăng 30% tỷ lệ chuyển đổi khách hàng mua bảo hiểm.',


      'banle.features.title': 'Tính năng Callbot Ngành Bán Lẻ',
      'banle.features.feature1.title': '1. Xây dựng kịch bản telesale bán lẻ ',
      'banle.features.feature1.0.title': 'Thiết kế kịch bản telesale bán lẻ',
      'banle.features.feature1.0.description':
        'Phần mềm auto call AI Callbot hỗ trợ xây kịch bản telesale bán lẻ mặt hàng tiêu dùng với các block tính năng cho phép xây dựng kịch bản telesale giúp gọi xác nhận đơn hàng, giới thiệu mặt hàng tiêu dùng bán lẻ như bán quần áo, đồng hồ, đồ chơi, vv',
      'banle.features.feature1.1.title': 'Kịch bản mẫu',
      'banle.features.feature1.1.description':
        '200+ kịch bản thực chiến được nghiên cứu và thiết kế cho các ngành nghề khác như: Bất động sản, tài chính, giáo dục, spa, bán lẻ, vv.',
      'banle.features.feature1.2.title': 'Chuyển máy tới nhân viên',
      'banle.features.feature1.2.description':
        'Dịch vụ auto call AI Callbot kết hợp linh hoạt cuộc gọi tự động và chuyển máy tới nhân viên bán lẻ, giúp gia tăng trải nghiệm khách hàng, tăng gấp 2 lần cơ hội chốt sales.',
      'banle.features.feature2.title': '2. Cài đặt chiến dịch thông minh',
      'banle.features.feature2.0.title': 'Giọng nói',
      'banle.features.feature2.0.description':
        'Lựa chọn giọng nói cho trợ lý ảo giống 99% người thật với đủ giọng nam nữ 3 vùng miền. Ngoài ra, hỗ trợ ghi âm giọng nói chuyên nghiệp giúp khách hàng trải nghiệm cuộc gọi thực tế nhất.',
      'banle.features.feature2.1.title': 'Khung thời gian gọi',
      'banle.features.feature2.1.description':
        'Phần mềm auto call gọi vào các khung giờ vàng đã được cài đặt giúp chạy chiến dịch telesale mặt hàng kinh doanh bán lẻ, chăm sóc khách hàng đúng thời điểm mà không cần nhiều nguồn lực.',
      'banle.features.feature2.2.title': 'Cài đặt gọi lại ',
      'banle.features.feature2.2.description':
        'Hệ thống tự động gọi lại cho khách hàng tiêu dùng máy bận, không nghe máy theo số lần được cài đặt, giúp tiết kiệm thời gian quay số gọi lại. ',
      'banle.features.feature3.title': '3. Báo cáo và đánh giá chi tiết',
      'banle.features.feature3.0.title': 'Báo cáo tổng quan',
      'banle.features.feature3.0.description':
        'Báo cáo tổng quan của dịch vụ auto call AI Callbot giúp đánh giá tiến độ chiến dịch telesale bán lẻ, chi phí, thời lượng, trạng thái cuộc gọi trong từng khung giờ. Từ đó giúp đánh giá tổng quan hiệu quả chiến dịch, để chỉnh sửa kịch bản telesale bán lẻ, và quản lý nhân viên bán lẻ hiệu quả.',
      'banle.features.feature3.1.title': 'Báo cáo chi tiết',
      'banle.features.feature3.1.description':
        'Báo cáo chi tiết của phần mềm auto call AI Callbot cho phép xuất báo cáo Excel, nghe lại file ghi âm, chuyển cuộc gọi sang dạng văn bản (speech to text) và phân loại data khách hàng có nhu cầu tiêu dùng theo thông tin cuộc gọi dễ dàng.',
      'banle.features.feature3.2.title': 'Đánh giá chất lượng cuộc gọi',
      'banle.features.feature3.2.description':
        'Giải pháp auto call AI Callbot sẽ tự động đánh giá chất lượng cuộc gọi telesale bán lẻ bằng AI, giúp quản lý nhân viên bán hiệu quả và nội dung cuộc gọi nhanh chóng. ',
      'banle.features.feature4.title': ' 4. Tích hợp dễ dàng, đa kênh',
      'banle.features.feature4.0.title': 'Tích hợp tìm kiếm data khách hàng tiềm năng ULEAD',
      'banle.features.feature4.0.description':
        'Công cụ tìm kiếm data khách hàng ULEAD giúp chủ cửa hàng bán lẻ lấy số điện thoại từ uid facebook, tìm data khách hàng có nhu cầu tiêu dùng từ dữ liệu lớn nhanh chóng.',
      'banle.features.feature4.1.title': 'Tích hợp tổng đài ảo telesale Call Center',
      'banle.features.feature4.1.description':
        'Data khách hàng tiêu dùng được tích hợp vào Tổng đài ảo Call Center Quaysố nhanh, giúp quản lý thông tin khách hàng dễ dàng hơn, nhân viên bán lẻ tập trung bán hàng, chốt đơn, đóng gói.',
      'banle.features.feature4.2.title': 'Tích hợp SMS, brandname, CRM, API',
      'banle.features.feature4.2.description':
        'Data khách hàng tiêu dùng được tích hợp vào SMS tin nhắn, SMS brandname, hệ thống CRM và API giúp tăng 30% tỷ lệ chuyển đổi bán hàng.',
  

  'contact_form.modal_title': 'Liên hệ tư vấn',
  'contact_form.form.name.label': 'Họ và Tên',
  'contact_form.thank_you': 'Chúng tôi sẽ liên hệ trong thời gian sớm nhất',
  'contact_form.form.email.label': 'Địa chỉ Email',
  'contact_form.form.phone_number.label': 'Số điện thoại',
  'contact_form.form.industry.label': 'Ngành nghề',
  'contact_form.form.demands.label': 'Số cuộc gọi/tháng',
  'contact_form.form.notes.label': 'Ghi chú',
  'contact_form.form.send_button': 'Gửi',
  'contact_form.industries.REAL_ESTATE': 'Bất động sản',
  'contact_form.industries.FINANCE': 'Tài chính',
  'contact_form.industries.INSURANCE': 'Bảo hiểm',
  'contact_form.industries.EDUCATION': 'Giáo dục',
  'contact_form.industries.OTHER': 'Khác',
  'contact_form.industries.NULL': 'Chưa rõ',
  'error_messages.required': 'Không thể bỏ trống',
  'success_messages.calling': 'Chúng tôi sẽ gọi đến bạn trong giây lát...',
  'pricing.voice.title': 'Bảng Giá',
  'pricing.promotion.title': 'Khuyến mãi',
  'pricing.expire.title': 'Thời hạn',
  'pricing.voice.price': 'Chi phí',
  'pricing.voice.subtitle':
    'Chi phí thực tế cuộc gọi được tính trên block 6s tối ưu chi phí cho doanh nghiệp.',
  'pricing.voice.unit': '/phút',
  'pricing.voice.ucall_fee': 'Giá nền tảng UCALL',
  'pricing.voice.carrier_fee': 'Giá cước viễn thông',
  'pricing.voice.personalize_fee': 'Giá cá nhân hoá',
  'pricing.voice.custom_fee':
    'Giá cước viễn thông khi sử dụng đầu số tổng đài riêng của doanh nghiệp',
  'pricing.voice.custom_fee_description':
    'KH trả trực tiếp cho nhà mạng, tuỳ thuộc vào nhà mạng khách hàng đăng ký',
  'pricing.plan.title': 'Gói bán',
  'pricing.plan.subtitle': '1M = 1,000,000 VNĐ',

  'callbot.solution_banner.title': 'Dịch Vụ Callbot AI Auto Call',
  'callbot.solution_banner.text1':
    'Callbot AI Auto Call là giải pháp trí tuệ nhân tạo giúp doanh nghiệp',
  'callbot.solution_banner.text2': '• Tối ưu chi phí nhân sự và vận hành',
  'callbot.solution_banner.text3': '• Tiết kiệm thời gian Telesales và Chăm sóc khách hàng',
  'callbot.solution_banner.text4': '• Gia tăng hiệu quả Kinh doanh',
  'call_center.solution_banner.title': 'Phần mềm Tổng Đài Thông Minh',
  'call_center.solution_banner.text1':
    'Tổng đài thông minh là giải pháp tổng đài ảo giúp doanh nghiệp',
  'call_center.solution_banner.text2': '• Tối ưu chi phí quản lý và vận hành tổng đài',
  'call_center.solution_banner.text3': '• Tiết kiệm thời gian Telesales và Chăm sóc khách hàng',
  'call_center.solution_banner.text4': '• Gia tăng hiệu quả Kinh doanh',
  'uleads.solution_banner.title': 'Giải Pháp Tìm Kiếm Data Khách Hàng uLeads',
  'uleads.solution_banner.text1':
    'Tìm kiếm data khách hàng (uLeads) là giải pháp giúp doanh nghiệp ',
  'uleads.solution_banner.text2': '• Tìm kiếm hàng ngàn khách hàng tiềm năng trong 1 phút',
  'uleads.solution_banner.text3': '• Lọc big data với bộ lọc tìm kiếm thông minh',
  'uleads.solution_banner.text4': '• Giúp doanh nghiệp hiểu rõ insight khách hàng',
  'use_cases.REAL_ESTATE': 'Bất động sản',
  'use_cases.INSURANCE': 'Bảo hiểm',
  'use_cases.FINANCE': 'Tài chính',
  'use_cases.EDUCATION': 'Giáo dục',
  'use_cases.BEAUTY': 'Khác',
  'use_cases.SOFTWARE': 'Phần Mềm',
  'use_cases.ADVISORY': 'Tư Vấn Thuế',
  'use_cases.MEDICAL': 'Y Tế',
  'use_cases.EVENT': 'Sự Kiện',
  'use_cases.HIRING': 'Tuyển Đại Lý',
  'use_cases.OTHER': 'Khác',
  'problems.title': 'Vấn đề của doanh nghiệp',

  'callbot.use_case.title': 'Ứng dụng giải pháp',
  'callbot.use_cases.REAL_ESTATE': 'Bất động sản',
  'use_cases.callbot.REAL_ESTATE.detail':
    'Bất động sản được đánh giá là một trong những lĩnh vực kinh doanh phát triển và tăng trưởng mạnh mẽ nhất hiện nay. Chính vì vậy việc các nhà đầu tư luôn tìm cách để đẩy mạnh quảng cáo và tiếp thị là việc làm quan trọng. Một trong số những cách làm phổ biến hiện nay mà nhiều đơn vị lựa chọn đó chính là xây dựng hoặc thuê ngoài đội ngũ Telesale để thực hiện các cuộc gọi đến với khách hàng.\n' +
    '\n' +
    'Với việc thị trường công nghệ ngày càng phát triển thì lựa chọn những ứng dụng công nghệ để việc chăm sóc khách hàng được tốt hơn đang được nhiều đơn vị quan tâm. Một trong số đó phải kể đến đó chính là giải pháp Auto call của tổng đài ảo Callbot \n',
  'callbot.use_cases.FINANCE': 'Tài chính',
  'use_cases.callbot.FINANCE.detail':
    'Doanh nghiệp tài chính là loại hình tổ chức tín dụng phi ngân hàng. Tổ chức này có chức năng sử dụng vốn tự có, vốn huy động và các nguồn vốn khác để cho vay, đầu tư cung ứng các dịch vụ tư vấn tài chính, tiền tệ và thực hiện một số dịch vụ khác theo quy định của pháp luật. Bởi vậy mà các tổ chức tín dụng luôn tìm cách để đẩy mạnh quảng cáo và tiếp thị đến khách hàng của mình. Một trong số những cách làm phổ biến hiện nay mà nhiều đơn vị lựa chọn đó chính là xây dựng hoặc thuê ngoài đội ngũ Telesale để thực hiện các cuộc gọi đến với khách hàng.\n' +
    '\n' +
    'Thấu hiểu được những khó khăn mà doanh nghiệp Tài chính đang gặp phải, giải pháp Callbot Auto Call được ra đời nhằm tiếp cận khách hàng một cách nhanh chóng và tăng khả năng kinh doanh của doanh nghiệp Tài Chính một cách đáng kể. \n',
  'callbot.use_cases.INSURANCE': 'Bảo hiểm',
  'use_cases.callbot.INSURANCE.detail':
    'Trên thị trường hiện nay, các loại bảo hiểm được phân chia rất cụ thể dựa trên đặc điểm đối tượng và nhiều doanh nghiệp bảo hiểm đang phát triển vô cùng mạnh mẽ, liên tiếp ra mắt các gói sản phẩm mới đáp ứng đa dạng nhu cầu của khách hàng. Cùng với đó, nâng cao chất lượng dịch vụ là một trong những yếu tố quan trọng giúp các doanh nghiệp bảo hiểm gia tăng lợi thế cạnh tranh, thu hút khách hàng mới và giữ chân khách hàng trung thành. \n' +
    '\n' +
    'Vậy nên bài toán đặt ra là cần có một giải pháp phù hợp để hỗ trợ doanh nghiệp giải quyết mọi vấn đề trên. Chính vì vậy giải pháp Callbot Ai Auto Call đã ra đời.\n',
  'callbot.use_cases.EDUCATION': 'Giáo dục',
  'use_cases.callbot.EDUCATION.detail':
    'Hiện nay có rất nhiều trung tâm giáo dục đang gặp khó khăn trong các vấn đề về việc quá tải data trong mùa cao điểm cũng như chi phí cho nhân sự lớn để phân loại tệp khách hàng thô. Các trung tâm Giáo Dục thường xuyên phải Telesales tìm kiếm các học viên mới mỗi ngày. Ngoài ra, các trung tâm còn phải gọi điện nhắc lịch học viên trong các khóa học, các buổi đào tạo cũng như tư vấn, chăm sóc học viên cũ để hiểu thêm nhu cầu của học viên. Với việc thực hiện các cuộc gọi Telesales và chăm sóc khách hàng khiến các trung tâm Giáo Dục tiêu tốn một khoản lớn ngân sách. \n' +
    '\n' +
    'Để giải quyết những khó khăn trên, UCALL đã cho ra đời giải pháp gọi tự động callbot để có thể giải quyết được vấn đề trên một cách nhanh chóng và dễ dàng nhất. Hiện tại, giải pháp callbot gọi tự động đang được nhiều tổ chức giáo dục quan tâm và lựa chọn để hỗ trợ kinh doanh được tốt hơn\n',

  'callbot.use_cases.BEAUTY': 'Khác',
  'callbot.use_cases.BEAUTY2': 'Làm đẹp',
  'callbot.use_cases.SOFTWARE': 'Phần Mềm',
  'callbot.use_cases.ADVISORY': 'Tư Vấn Thuế',
  'callbot.use_cases.MEDICAL': 'Y Tế',
  'callbot.use_cases.EVENT': 'Sự Kiện',
  'callbot.use_cases.HIRING': 'Tuyển Đại Lý',
  'callbot.use_cases.OTHER': 'Khác',
  'use_cases.callbot.OTHER.detail':
    'Hiện nay, khi thị trường công nghệ ngày càng phát triển; điều này đồng nghĩa việc xuất hiện những giải pháp công nghệ để hỗ trợ kinh doanh cho doanh nghiệp ngày càng nhiều. Một trong số đó chính là sự xuất hiện của Callbot. Với mục đích nhằm tiếp cận khách hàng một cách nhanh chóng và tăng khả năng kinh doanh một cách đáng kể. \n' +
    '\n' +
    'Bởi vậy, giải pháp callbot gọi tự động đang được nhiều doanh nghiệp quan tâm và lựa chọn để hỗ trợ kinh doanh được tốt hơn. Để có thể dẫn đầu trong ngành; các đơn vị luôn cố gắng làm hài lòng khách hàng của mình. Vậy nên việc lựa chọn callbot được xem là giải pháp giúp nâng cao trải nghiệm khách hàng; đưa hoạt động kinh doanh của bạn phát triển sang một trang mới.\n',

  'call_center.use_case.title': 'Ứng dụng giải pháp',
  'call_center.use_cases.REAL_ESTATE': 'Bất động sản',
  'use_cases.call_center.REAL_ESTATE.detail':
    'Bất động sản được đánh giá là một trong những lĩnh vực kinh doanh phát triển và tăng trưởng mạnh mẽ nhất hiện nay. Chính vì vậy việc các nhà đầu tư luôn tìm cách để đẩy mạnh quảng cáo và tiếp thị là việc làm quan trọng. Một trong số những cách làm phổ biến hiện nay mà nhiều đơn vị lựa chọn đó chính là xây dựng hoặc thuê ngoài đội ngũ Telesale để thực hiện các cuộc gọi đến với khách hàng.\n' +
    '\n' +
    'Với việc thị trường công nghệ ngày càng phát triển thì lựa chọn những ứng dụng công nghệ để việc chăm sóc khách hàng được tốt hơn đang được nhiều đơn vị quan tâm. Một trong số đó phải kể đến đó chính là giải pháp Auto call của tổng đài ảo Callbot \n',
  'call_center.use_cases.FINANCE': 'Tài chính',
  'use_cases.call_center.FINANCE.detail':
    'Doanh nghiệp tài chính là loại hình tổ chức tín dụng phi ngân hàng. Tổ chức này có chức năng sử dụng vốn tự có, vốn huy động và các nguồn vốn khác để cho vay, đầu tư cung ứng các dịch vụ tư vấn tài chính, tiền tệ và thực hiện một số dịch vụ khác theo quy định của pháp luật. Bởi vậy mà các tổ chức tín dụng luôn tìm cách để đẩy mạnh quảng cáo và tiếp thị đến khách hàng của mình. Một trong số những cách làm phổ biến hiện nay mà nhiều đơn vị lựa chọn đó chính là xây dựng hoặc thuê ngoài đội ngũ Telesale để thực hiện các cuộc gọi đến với khách hàng.\n' +
    '\n' +
    'Thấu hiểu được những khó khăn mà doanh nghiệp Tài chính đang gặp phải, giải pháp Callbot Auto Call được ra đời nhằm tiếp cận khách hàng một cách nhanh chóng và tăng khả năng kinh doanh của doanh nghiệp Tài Chính một cách đáng kể. \n',
  'call_center.use_cases.INSURANCE': 'Bảo hiểm',
  'use_cases.call_center.INSURANCE.detail':
    'Trên thị trường hiện nay, các loại bảo hiểm được phân chia rất cụ thể dựa trên đặc điểm đối tượng và nhiều doanh nghiệp bảo hiểm đang phát triển vô cùng mạnh mẽ, liên tiếp ra mắt các gói sản phẩm mới đáp ứng đa dạng nhu cầu của khách hàng. Cùng với đó, nâng cao chất lượng dịch vụ là một trong những yếu tố quan trọng giúp các doanh nghiệp bảo hiểm gia tăng lợi thế cạnh tranh, thu hút khách hàng mới và giữ chân khách hàng trung thành. \n' +
    '\n' +
    'Vậy nên bài toán đặt ra là cần có một giải pháp phù hợp để hỗ trợ doanh nghiệp giải quyết mọi vấn đề trên. Chính vì vậy giải pháp Callbot Ai Auto Call đã ra đời.\n',
  'call_center.use_cases.EDUCATION': 'Giáo dục',
  'use_cases.call_center.EDUCATION.detail':
    'Hiện nay có rất nhiều trung tâm giáo dục đang gặp khó khăn trong các vấn đề về việc quá tải data trong mùa cao điểm cũng như chi phí cho nhân sự lớn để phân loại tệp khách hàng thô. Các trung tâm Giáo Dục thường xuyên phải Telesales tìm kiếm các học viên mới mỗi ngày. Ngoài ra, các trung tâm còn phải gọi điện nhắc lịch học viên trong các khóa học, các buổi đào tạo cũng như tư vấn, chăm sóc học viên cũ để hiểu thêm nhu cầu của học viên. Với việc thực hiện các cuộc gọi Telesales và chăm sóc khách hàng khiến các trung tâm Giáo Dục tiêu tốn một khoản lớn ngân sách. \n' +
    '\n' +
    'Để giải quyết những khó khăn trên, UCALL đã cho ra đời giải pháp gọi tự động callbot để có thể giải quyết được vấn đề trên một cách nhanh chóng và dễ dàng nhất. Hiện tại, giải pháp callbot gọi tự động đang được nhiều tổ chức giáo dục quan tâm và lựa chọn để hỗ trợ kinh doanh được tốt hơn\n',
  'call_center.use_cases.BEAUTY': 'Khác',
  'call_center.use_cases.BEAUTY2': 'Làm đẹp',
  'call_center.use_cases.SOFTWARE': 'Phần Mềm',
  'call_center.use_cases.ADVISORY': 'Tư Vấn Thuế',
  'call_center.use_cases.MEDICAL': 'Y Tế',
  'call_center.use_cases.EVENT': 'Sự Kiện',
  'call_center.use_cases.HIRING': 'Tuyển Đại Lý',
  'call_center.use_cases.OTHER': 'Khác',
  'use_cases.call_center.OTHER.detail':
    'Hiện nay, khi thị trường công nghệ ngày càng phát triển; điều này đồng nghĩa việc xuất hiện những giải pháp công nghệ để hỗ trợ kinh doanh cho doanh nghiệp ngày càng nhiều. Một trong số đó chính là sự xuất hiện của Callbot. Với mục đích nhằm tiếp cận khách hàng một cách nhanh chóng và tăng khả năng kinh doanh một cách đáng kể. \n' +
    '\n' +
    'Bởi vậy, giải pháp callbot gọi tự động đang được nhiều doanh nghiệp quan tâm và lựa chọn để hỗ trợ kinh doanh được tốt hơn. Để có thể dẫn đầu trong ngành; các đơn vị luôn cố gắng làm hài lòng khách hàng của mình. Vậy nên việc lựa chọn callbot được xem là giải pháp giúp nâng cao trải nghiệm khách hàng; đưa hoạt động kinh doanh của bạn phát triển sang một trang mới.\n',

  'uleads.use_case.title': 'Ứng dụng giải pháp',
  'uleads.use_cases.REAL_ESTATE': 'Bất động sản',
  'use_cases.uleads.REAL_ESTATE.detail':
    'Bất động sản được đánh giá là một trong những lĩnh vực kinh doanh phát triển và tăng trưởng mạnh mẽ nhất hiện nay. Chính vì vậy việc các nhà đầu tư luôn tìm cách để đẩy mạnh quảng cáo và tiếp thị là việc làm quan trọng. Một trong số những cách làm phổ biến hiện nay mà nhiều đơn vị lựa chọn đó chính là xây dựng hoặc thuê ngoài đội ngũ Telesale để thực hiện các cuộc gọi đến với khách hàng.\n' +
    '\n' +
    'Với việc thị trường công nghệ ngày càng phát triển thì lựa chọn những ứng dụng công nghệ để việc chăm sóc khách hàng được tốt hơn đang được nhiều đơn vị quan tâm. Một trong số đó phải kể đến đó chính là giải pháp Auto call của tổng đài ảo Callbot \n',
  'uleads.use_cases.FINANCE': 'Tài chính',
  'use_cases.uleads.FINANCE.detail':
    'Doanh nghiệp tài chính là loại hình tổ chức tín dụng phi ngân hàng. Tổ chức này có chức năng sử dụng vốn tự có, vốn huy động và các nguồn vốn khác để cho vay, đầu tư cung ứng các dịch vụ tư vấn tài chính, tiền tệ và thực hiện một số dịch vụ khác theo quy định của pháp luật. Bởi vậy mà các tổ chức tín dụng luôn tìm cách để đẩy mạnh quảng cáo và tiếp thị đến khách hàng của mình. Một trong số những cách làm phổ biến hiện nay mà nhiều đơn vị lựa chọn đó chính là xây dựng hoặc thuê ngoài đội ngũ Telesale để thực hiện các cuộc gọi đến với khách hàng.\n' +
    '\n' +
    'Thấu hiểu được những khó khăn mà doanh nghiệp Tài chính đang gặp phải, giải pháp Callbot Auto Call được ra đời nhằm tiếp cận khách hàng một cách nhanh chóng và tăng khả năng kinh doanh của doanh nghiệp Tài Chính một cách đáng kể. \n',
  'uleads.use_cases.INSURANCE': 'Bảo hiểm',
  'use_cases.uleads.INSURANCE.detail':
    'Trên thị trường hiện nay, các loại bảo hiểm được phân chia rất cụ thể dựa trên đặc điểm đối tượng và nhiều doanh nghiệp bảo hiểm đang phát triển vô cùng mạnh mẽ, liên tiếp ra mắt các gói sản phẩm mới đáp ứng đa dạng nhu cầu của khách hàng. Cùng với đó, nâng cao chất lượng dịch vụ là một trong những yếu tố quan trọng giúp các doanh nghiệp bảo hiểm gia tăng lợi thế cạnh tranh, thu hút khách hàng mới và giữ chân khách hàng trung thành. \n' +
    '\n' +
    'Vậy nên bài toán đặt ra là cần có một giải pháp phù hợp để hỗ trợ doanh nghiệp giải quyết mọi vấn đề trên. Chính vì vậy giải pháp Callbot Ai Auto Call đã ra đời.\n',
  'uleads.use_cases.EDUCATION': 'Giáo dục',
  'use_cases.uleads.EDUCATION.detail':
    'Hiện nay có rất nhiều trung tâm giáo dục đang gặp khó khăn trong các vấn đề về việc quá tải data trong mùa cao điểm cũng như chi phí cho nhân sự lớn để phân loại tệp khách hàng thô. Các trung tâm Giáo Dục thường xuyên phải Telesales tìm kiếm các học viên mới mỗi ngày. Ngoài ra, các trung tâm còn phải gọi điện nhắc lịch học viên trong các khóa học, các buổi đào tạo cũng như tư vấn, chăm sóc học viên cũ để hiểu thêm nhu cầu của học viên. Với việc thực hiện các cuộc gọi Telesales và chăm sóc khách hàng khiến các trung tâm Giáo Dục tiêu tốn một khoản lớn ngân sách. \n' +
    '\n' +
    'Để giải quyết những khó khăn trên, UCALL đã cho ra đời giải pháp gọi tự động callbot để có thể giải quyết được vấn đề trên một cách nhanh chóng và dễ dàng nhất. Hiện tại, giải pháp callbot gọi tự động đang được nhiều tổ chức giáo dục quan tâm và lựa chọn để hỗ trợ kinh doanh được tốt hơn\n',
  'uleads.use_cases.BEAUTY': 'Khác',
  'uleads.use_cases.BEAUTY2': 'Làm đẹp',
  'uleads.use_cases.SOFTWARE': 'Phần Mềm',
  'uleads.use_cases.ADVISORY': 'Tư Vấn Thuế',
  'uleads.use_cases.MEDICAL': 'Y Tế',
  'uleads.use_cases.EVENT': 'Sự Kiện',
  'uleads.use_cases.HIRING': 'Tuyển Đại Lý',
  'uleads.use_cases.OTHER': 'Khác',
  'use_cases.uleads.OTHER.detail':
    'Hiện nay, khi thị trường công nghệ ngày càng phát triển; điều này đồng nghĩa việc xuất hiện những giải pháp công nghệ để hỗ trợ kinh doanh cho doanh nghiệp ngày càng nhiều. Một trong số đó chính là sự xuất hiện của Callbot. Với mục đích nhằm tiếp cận khách hàng một cách nhanh chóng và tăng khả năng kinh doanh một cách đáng kể. \n' +
    '\n' +
    'Bởi vậy, giải pháp callbot gọi tự động đang được nhiều doanh nghiệp quan tâm và lựa chọn để hỗ trợ kinh doanh được tốt hơn. Để có thể dẫn đầu trong ngành; các đơn vị luôn cố gắng làm hài lòng khách hàng của mình. Vậy nên việc lựa chọn callbot được xem là giải pháp giúp nâng cao trải nghiệm khách hàng; đưa hoạt động kinh doanh của bạn phát triển sang một trang mới.\n',

  'use_cases.find_out': 'Tìm hiểu thêm',
  'customers.title': 'Khách Hàng và Đối Tác',
  'pros.title': 'Lợi ích',
  'feedbacks.title': 'Phản hồi khách hàng',
  'cases.solution.title': 'Giải pháp của UCALL',
  'cases.promotion_banner': 'Nâng cao hiệu quả kinh doanh',
  'cases.REAL_ESTATE.title':
    'CALLBOT AI AUTO CALL\n' + 'TỐI ƯU 50% CHI PHÍ\n' + 'TELESALE BẤT ĐỘNG SẢN',
  'cases.REAL_ESTATE.subtitle':
    'Callbot AI gọi tự động đáp ứng nhu cầu quản lý và tối ưu chi phí chuyển đổi khách hàng tiềm năng trong lĩnh vực Bất Động Sản',
  'cases.REAL_ESTATE.problem1.title': 'Thiếu data',
  'cases.REAL_ESTATE.problem1.subtitle':
    'Thiếu nguồn data vào những thời điểm quan trọng, khiến cho việc kinh doanh Bất Động Sản bị hạn chế',
  'cases.REAL_ESTATE.problem2.title': 'Tốn thời gian',
  'cases.REAL_ESTATE.problem2.subtitle':
    'Doanh nghiệp phải dành hàng giờ mỗi ngày để tìm ra khách hàng tiềm năng trong tệp data thô (khoảng 70% data không phải khách hàng tiềm năng)',
  'cases.REAL_ESTATE.problem3.title': 'Chi phí cao',
  'cases.REAL_ESTATE.problem3.subtitle':
    'Tốn 50% chi phí nhân sự Telesales vào việc tìm kiếm khách hàng tiềm năng, chưa kể đến các chi phí vận hành khác',
  'cases.REAL_ESTATE.problem4.title': 'Bỏ lỡ cơ hội',
  'cases.REAL_ESTATE.problem4.subtitle':
    'Doanh nghiệp Bất Động Sản không kịp thời tiếp cận khách hàng tiềm năng có thể mất đi cơ hội kinh doanh trong tương lai',
  'cases.REAL_ESTATE.solution1': 'Tìm kiếm data khách hàng có nhu cầu về Bất Động Sản',
  'cases.REAL_ESTATE.solution2':
    'Hỗ trợ xây dựng kịch bản telesales phù hợp với đối tượng khách hàng Bất Động Sản',
  'cases.REAL_ESTATE.solution3': 'Thực hiện 2000 cuộc gọi đến khách hàng chỉ trong vòng 1 giờ',
  'cases.REAL_ESTATE.solution4': 'Tích hợp đa kênh, báo cáo thông minh và quản lý thông tin khách hàng dễ dàng',
  'cases.SPA.solution1':
    'Tìm kiếm data khách hàng có nhu cầu đi spa làm đẹp từ dữ liệu lớn cho chủ spa',
  'cases.SPA.solution2':
    'Hỗ trợ xây dựng kịch bản telesale spa làm đẹp, telesale chăm sóc khách hàng cũ thực chiến ',
  'cases.SPA.solution3':
    'Thực hiện hơn 2000 cuộc gọi tự động đến khách hàng tiềm năng chỉ trong vòng 1 giờ',
  'cases.SPA.solution4':
    'Tích hợp đa kênh, báo cáo thông minh và quản lý thông tin khách hàng dễ dàng',
  'cases.RETAIL.solution1':
    'Tìm kiếm data khách hàng tiềm năng có nhu cầu tiêu dùng từ dữ liệu lớn ngành bán lẻ',
  'cases.RETAIL.solution2':
    'Hỗ trợ xây dựng kịch bản telesale bán lẻ hàng tiêu dùng thực chiến chốt sale ',
  'cases.RETAIL.solution3':
    'Thực hiện hơn 2000 cuộc gọi tự động đến khách hàng tiềm năng chỉ trong vòng 1 giờ',
  'cases.RETAIL.solution4':
    'Tích hợp đa kênh, báo cáo thông minh và quản lý thông tin khách hàng dễ dàng',
  'cases.REAL_ESTATE.pros1':
    'Nhanh chóng tìm ra khách hàng tiềm năng, gia tăng tỷ lệ chuyển đổi cho doanh nghiệp Bất Động Sản',
  'cases.REAL_ESTATE.pros2': 'Tiết kiệm chi phí và thời gian cho doanh nghiệp\n',
  'cases.REAL_ESTATE.pros3':
    'Giảm tải khối lượng công việc, giúp doanh nghiệp tập chung vào các công việc quan trọng',
  'cases.REAL_ESTATE.pros4': 'Nâng cao hiệu quả kinh doanh cho doanh nghiệp\n',
  'cases.FINANCE.title':
    'CALLBOT AI AUTO CALL \n' + 'TỐI ƯU 50% CHI PHÍ \n' + 'TELESALE TÀI CHÍNH\n',
  'cases.FINANCE.subtitle':
    'Callbot AI gọi tự động đáp ứng nhu cầu quản lý và tối ưu chi phí chuyển đổi khách hàng tiềm năng trong lĩnh vực Tài Chính',
  'cases.FINANCE.problem1.title': 'Thiếu data',
  'cases.FINANCE.problem1.subtitle':
    'Thiếu nguồn data vào những thời điểm quan trọng, khiến cho việc kinh doanh Tài Chính bị hạn chế',
  'cases.FINANCE.problem2.title': 'Tốn thời gian',
  'cases.FINANCE.problem2.subtitle':
    'Mỗi tư vấn viên Tài Chính phải dành 6 tiếng mỗi ngày cho việc gọi và tìm ra khách hàng có nhu cầu tài chính trong tệp data thô (khoảng 70% data không phải khách hàng tiềm năng), giảm đi năng xuất làm việc.',
  'cases.FINANCE.problem3.title': 'Chi phí cao',
  'cases.FINANCE.problem3.subtitle':
    'Tốn 50% chi phí nhân sự Telesales cho việc tìm ra khách hàng tiềm năng, chưa kể đến các chi phí vận hành khác.',
  'cases.FINANCE.problem4.title': 'Bỏ lỡ cơ hội',
  'cases.FINANCE.problem4.subtitle':
    'Nhân viên Telesales chỉ xử lý được 1 cuộc gọi trong 1 thời điểm. Điều này dẫn đến năng xuất thấp và không thể tiếp cận khối lượng lớn khách hàng có nhu cầu Tài Chính, mất đi nhiều những cơ hội bán hàng.',
  'cases.FINANCE.solution1': 'Tìm kiếm data khách hàng có nhu cầu về Tài Chính',
  'cases.FINANCE.solution2':
    'Hỗ trợ xây dựng kịch bản Telesales phù hợp với đối tượng khách hàng Tài Chính',
  'cases.FINANCE.solution3': 'Thực hiện 2000 cuộc gọi đến khách hàng chỉ trong vòng 1 giờ',
  'cases.FINANCE.solution4': 'Tích hợp đa kênh, báo cáo thông minh và quản lý thông tin khách hàng dễ dàng',
  'cases.FINANCE.pros1':
    'Nhanh chóng tìm ra khách hàng tiềm năng, gia tăng tỷ lệ chuyển đổi cho doanh nghiệp Tài Chính',
  'cases.FINANCE.pros2': 'Tiết kiệm chi phí và thời gian cho doanh nghiệp\n',
  'cases.FINANCE.pros3':
    'Giảm tải khối lượng công việc, giúp doanh nghiệp tập chung vào các công việc quan trọng',
  'cases.FINANCE.pros4': 'Nâng cao hiệu quả kinh doanh cho doanh nghiệp\n',
  'cases.INSURANCE.title':
    'CALLBOT AI AUTO CALL \n' + 'TỐI ƯU 50% CHI PHÍ \n' + 'TELESALE BẢO HIỂM\n',
  'cases.INSURANCE.subtitle':
    'Callbot AI gọi tự động đáp ứng nhu cầu quản lý và tối ưu chi phí chuyển đổi khách hàng tiềm năng trong lĩnh vực Bảo Hiểm',
  'cases.INSURANCE.problem1.title': 'Thiếu data',
  'cases.INSURANCE.problem1.subtitle':
    'Thiếu nguồn data vào những thời điểm quan trọng, khiến cho việc kinh doanh Bảo Hiểm bị hạn chế',
  'cases.INSURANCE.problem2.title': 'Tốn thời gian',
  'cases.INSURANCE.problem2.subtitle':
    'Mỗi tư vấn viên Bảo Hiểm phải dành 6 tiếng mỗi ngày cho việc gọi và tìm ra khách hàng có nhu cầu mua Bảo Hiểm trong tệp data thô (khoảng 70% data không phải khách hàng tiềm năng), giảm đi năng xuất làm việc.',
  'cases.INSURANCE.problem3.title': 'Chi phí cao',
  'cases.INSURANCE.problem3.subtitle':
    'Tốn 50% chi phí nhân sự Telesales cho việc tìm ra khách hàng tiềm năng, chưa kể đến các chi phí vận hành khác.',
  'cases.INSURANCE.problem4.title': 'Bỏ lỡ cơ hội',
  'cases.INSURANCE.problem4.subtitle':
    'Nhân viên Telesales chỉ xử lý được 1 cuộc gọi trong 1 thời điểm. Điều này dẫn đến năng xuất thấp và không thể tiếp cận khối lượng lớn khách hàng có nhu cầu về Bảo Hiểm.',
  'cases.INSURANCE.solution1': 'Tìm kiếm data khách hàng có nhu cầu mua Bảo Hiểm',
  'cases.INSURANCE.solution2':
    'Hỗ trợ xây dựng kịch bản Telesales Bảo Hiểm hiệu quả cho doanh nghiệp',
  'cases.INSURANCE.solution3': 'Thực hiện 2000 cuộc gọi đến khách hàng chỉ trong vòng 1 giờ',
  'cases.INSURANCE.solution4': 'Tích hợp đa kênh, báo cáo thông minh và quản lý thông tin khách hàng dễ dàng',
  'cases.INSURANCE.pros1':
    'Nhanh chóng tìm ra khách hàng tiềm năng, gia tăng tỷ lệ chuyển đổi cho doanh nghiệp Bảo Hiểm',
  'cases.INSURANCE.pros2': 'Tiết kiệm chi phí và thời gian cho doanh nghiệp\n',
  'cases.INSURANCE.pros3':
    'Giảm tải khối lượng công việc, giúp doanh nghiệp tập chung vào các công việc quan trọng',
  'cases.INSURANCE.pros4': 'Nâng cao hiệu quả kinh doanh cho doanh nghiệp\n',
  'cases.EDUCATION.title':
    'CALLBOT AI AUTO CALL \n' + 'TỐI ƯU CHI PHÍ \n' + 'TELESALE & CSKH NGÀNH GIÁO DỤC\n',
  'cases.EDUCATION.subtitle':
    'Callbot AI gọi tự động đáp ứng nhu cầu quản lý, chăm sóc khách hàng và tối ưu chi phí chuyển đổi khách hàng tiềm năng ngành Giáo Dục',
  'cases.EDUCATION.problem1.title': 'Thiếu data',
  'cases.EDUCATION.problem1.subtitle':
    'Thiếu nguồn data vào những thời điểm quan trọng, khiến cho việc kinh doanh của trung tâm bị hạn chế',
  'cases.EDUCATION.problem2.title': 'Tốn thời gian',
  'cases.EDUCATION.problem2.subtitle':
    'Các trung tâm Giáo Dục phải dành hàng giờ mỗi ngày cho việc chăm sóc các học viên cũ và tìm kiếm các học viên mới trong tệp data thô (khoảng 65% data không phải các ứng viên có nhu cầu).',
  'cases.EDUCATION.problem3.title': 'Chi phí cao',
  'cases.EDUCATION.problem3.subtitle':
    'Tốn 50% chi phí nhân sự Telesales và chăm sóc học viên, chưa kể đến các chi phí vận hành khác.',
  'cases.EDUCATION.problem4.title': 'Bỏ lỡ cơ hội',
  'cases.EDUCATION.problem4.subtitle':
    'Việc tiếp cận các học viên tiềm năng chậm, cũng giảm đi những cơ hội kinh doanh của trung tâm Giáo Dục. Không chăm sóc kịp các học viên cũng chính là lý do khiến họ chuyển sang các trung tâm khác.',
  'cases.EDUCATION.solution1': ' Tìm kiếm data học sinh, sinh viên có nhu cầu đăng ký khóa học từ cơ sở dữ liệu giáo dục',
  'cases.EDUCATION.solution2':
    'Hỗ trợ xây dựng kịch bản telesale giáo dục, telesale chăm sóc học viên chốt sale khóa học',
  'cases.EDUCATION.solution3': 'Thực hiện 2000 cuộc gọi đến khách hàng chỉ trong vòng 1 giờ',
  'cases.EDUCATION.solution4': 'Tích hợp đa kênh, báo cáo thông minh và quản lý thông tin khách hàng dễ dàng',
  'cases.EDUCATION.pros1':
    'Nhanh chóng tìm ra khách hàng tiềm năng, gia tăng tỷ lệ chuyển đổi cho trung tâm Giáo Dục',
  'cases.EDUCATION.pros2': 'Chăm sóc học viên thường xuyên, gia tăng tỷ lệ giữ chân khách hàng',
  'cases.EDUCATION.pros3':
    'Giảm tải khối lượng công việc, giúp tư vấn viên tập chung vào các công việc quan trọng',
  'cases.EDUCATION.pros4':
    'Nâng cao hiệu quả kinh doanh cho doanh nghiệp. Tiết kiệm chi phí và thời gian cho trung tâm.',
  'cases.OTHER.title': 'CALLBOT AI AUTO CALL \n' + 'TỐI ƯU CHI PHÍ \n' + 'TELESALE & CSKH\n',
  'cases.OTHER.subtitle':
    'Callbot AI gọi tự động đáp ứng nhu cầu quản lý, chăm sóc khách hàng và tối ưu chi phí chuyển đổi khách hàng tiềm năng cho các lĩnh vực khác như Phần mềm, Y Tế, Tư vấn thuế, Tuyển Đại Lý, Tổ chức sự kiện',
  'cases.OTHER.problem1.title': 'Thiếu data',
  'cases.OTHER.problem1.subtitle':
    'Thiếu nguồn data vào những thời điểm quan trọng, khiến cho việc kinh doanh bị hạn chế',
  'cases.OTHER.problem2.title': 'Tốn thời gian',
  'cases.OTHER.problem2.subtitle':
    'Các doanh nghiệp phải dành hàng giờ mỗi ngày cho việc chăm sóc khách hàng cũ và tìm kiếm các khách hàng mới trong tệp data thô (khoảng 65% data không phải các ứng viên có nhu cầu).',
  'cases.OTHER.problem3.title': 'Chi phí cao',
  'cases.OTHER.problem3.subtitle':
    'Tốn 50% chi phí nhân sự Telesales và chăm sóc học viên, chưa kể đến các chi phí vận hành khác.',
  'cases.OTHER.problem4.title': 'Bỏ lỡ cơ hội',
  'cases.OTHER.problem4.subtitle':
    'Việc tiếp cận các khách hàng tiềm năng chậm, cũng giảm đi những cơ hội kinh doanh của Doanh nghiệp. Không chăm sóc kịp các khách hàng cũng chính là lý do khiến họ chuyển sang các bên đối thủ khác.',
  'cases.OTHER.solution1': 'Tìm kiếm data khách hàng có nhu cầu',
  'cases.OTHER.solution2': 'Hỗ trợ xây dựng kịch bản telesales phù hợp với đối tượng khách hàng',
  'cases.OTHER.solution3': 'Thực hiện 2000 cuộc gọi đến khách hàng chỉ trong vòng 1 giờ',
  'cases.OTHER.solution4': 'Tích hợp đa kênh, báo cáo thông minh và quản lý thông tin khách hàng dễ dàng',
  'cases.OTHER.pros1':
    'Nhanh chóng tìm ra khách hàng tiềm năng, gia tăng tỷ lệ chuyển đổi cho Doanh nghiệp',
  'cases.OTHER.pros2': 'Chăm sóc học viên thường xuyên, gia tăng tỷ lệ giữ chân khách hàng',
  'cases.OTHER.pros3':
    'Tiết kiệm chi phí và thời gian cho trung tâm. Nâng cao hiệu quả kinh doanh cho trung tâm',
  'cases.OTHER.pros4':
    'Giảm tải khối lượng công việc, giúp tư vấn viên tập chung vào các công việc quan trọng',

  'callbot.features.feature1.image': 'xay-dung-kich-ban.png',
  'callbot.features.feature2.image': 'cai-dat-chien-dich.png',
  'callbot.features.feature3.image': 'bao-cao-tong-quan.png',
  'callbot.features.feature4.image': 'giao-dien-nhap-khach-hang.png',

  'call_center.features.feature1.image': 'telesales-quay-so-tu-dong.png',
  'call_center.features.feature2.image': 'telesales-cai-dat-chien-dich.png',
  'call_center.features.feature3.image': 'lich-su-cuoc-goi.png',
  'call_center.features.feature4.image': 'telesales-danh-sach-khach-hang.png',

  'uleads.features.feature1.image': 'giao-dien-nhap-khach-hang.png',
  'uleads.features.feature2.image': 'giao-dien-sua-kich-ban.png',
  'uleads.features.feature3.image': 'giao-dien-bao-cao.png',
  'uleads.features.feature4.image': 'telesales-danh-sach-khach-hang.png',
};
